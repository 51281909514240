import styled from '@emotion/styled';
import css from '@emotion/css';

export const ValuesSectionWrapper = styled.div<any>`
  width: 100%;
  height: auto;
  background-color: white;

  .values-section {
    .flex-col-text {
      border-bottom: 1px solid #b3baad;
      min-height: 300px;
      border-right: 1px solid #b3baad;

      h3 {
        text-align: left;
        min-height: 65px;
        margin-bottom: 15px;
      }
    }

    .flex-col-text:nth-child(4) {
      border-right: none;
    }
    .flex-col-text:nth-child(5) {
      border-bottom: 0;
    }
    .flex-col-text:nth-child(6) {
      border-bottom: 0;
    }
    .flex-col-text:nth-child(7) {
      border-bottom: 0;
    }
    .flex-col-text:nth-child(8) {
      border-right: none;
      border-bottom: none;
    }
  }

  @media screen and (max-width: 768px) {
    .values-section {
      padding: 40px 20px;

      .flex-col-text {
        min-height: auto;
        border-right: none;
        width: 100%;
        border-bottom: 1px solid #b3baad !important;
        padding: 20px;

        :last-child {
          border-bottom: none !important;
        }

        h3 {
          text-align: left;
          min-height: auto;
          margin-bottom: 15px;
        }
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .values-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .values-section {
      padding: 60px 50px;
      .flex-col-text {
        border-bottom: 1px solid #b3baad;
        min-height: 340px;
        border-right: 1px solid #b3baad;

        p {
          font-size: 14px;
        }

        h3 {
          text-align: left;
          min-height: 65px;
          margin-bottom: 15px;
        }
      }

      .flex-col-text:nth-child(4) {
        border-right: none;
      }
      .flex-col-text:nth-child(5) {
        border-bottom: 0;
      }
      .flex-col-text:nth-child(6) {
        border-bottom: 0;
      }
      .flex-col-text:nth-child(7) {
        border-bottom: 0;
      }
      .flex-col-text:nth-child(8) {
        border-right: none;
        border-bottom: none;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .values-section {
      padding: 120px 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .values-section {
      padding: 120px 150px;
    }
  }

  @media screen and (min-width: 1601px) {
    .values-section {
      padding: 120px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .values-section {
      padding: 120px 400px;
    }
  }
`;
