import styled from '@emotion/styled';
import css from '@emotion/css';

export const HeroImageWithTextWrapper = styled.div<any>`
  width: 100%;
  height: auto;
  min-height: 80vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  ${(props) => {
    return css`
      background-image: url(${props.backgroundImage});
      background-repeat: no-repeat;
      background-size: cover;
    `;
  }}

  @media screen and (max-width: 768px) {
    padding: 20px;
    min-height: 50vh;
    h1 {
      padding: 20px;
    }
    ${(props) => {
      return css`
        background-image: url(${props.backgroundMobileImage});
        background-repeat: no-repeat;
        background-size: cover;
      `;
    }}
  }
`;
