import { Link } from 'gatsby';
import { WriteToUsSection } from '../../../../pages_/home/new_write_to_us_section/WriteToUsSection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'rebass';
import {
  AboveTitle,
  BigTitle,
  FlexCol,
  FlexWrapper,
  Title,
  Wrapper,
  Text,
  Bold,
  Img,
  ImgWrapper,
  SmallTitle,
  Divider,
} from '../../../../global/new-global-components';
import { positions } from '../../../career/positions/positions-data';
import { CareerSectionWrapper } from './career.styles';

export const CareerSection = (props: any) => {
  const { t } = useTranslation();

  return (
    <CareerSectionWrapper>
      <Wrapper className="career-section">
        <AboveTitle marginBottom="15px" textAlign="center">
          {t('career_MainHeading')}
        </AboveTitle>
        <Title marginBottom="15px" textAlign="center" fontWeight="800">
          {t('career_Heading')}
        </Title>
        <Text textAlign="center" fontSize="20px">
          {t('career_SubHeading')}
        </Text>

        <Wrapper margin="30px auto" maxWidth="1200px">
          {positions &&
            positions.map((position: any) => (
              <Link className="career-link" key={position.url} to={`/sr/career/${position.url}`}>
                <Wrapper className="job-position-wrapper" borderRadius="20px" backgroundColor="white" marginBottom="30px">
                  <FlexWrapper className="job-position-flex-wrapper mobile-stay-flex" justifyContent="space-between" padding="20px 30px">
                    <FlexCol className="flex-col-80">
                      <SmallTitle textAlign="left" fontSize="24px">
                        {position.title}
                      </SmallTitle>
                      <Text textAlign="left">{position.subTitle}</Text>
                    </FlexCol>
                    <FlexCol width="36px">
                      <FlexWrapper>
                        <img width="36px" src="/vectors/forward-icon.svg" />
                      </FlexWrapper>
                    </FlexCol>
                  </FlexWrapper>
                </Wrapper>
              </Link>
            ))}
        </Wrapper>

        <WriteToUsSection
          maxWidth="1200px"
          disableFAQButton={true}
          backgroundImage="/new-images/career-write-to-us.png"
          backgroundMobileImage="/new-images/career-write-to-us-mobile.png"
          customTitle="Nisi pronašao/la poziciju koja ti odgovara?"
          customSubTitle="To nije razlog za brigu. Pošalji nam svoju biografiju na hr@vozzi.eu i mi ćemo te kontaktirati čim se odgovarajuća pozicija otvori."
        />
      </Wrapper>
    </CareerSectionWrapper>
  );
};
