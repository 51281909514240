import styled from '@emotion/styled';
import css from '@emotion/css';

export const CareerSectionWrapper = styled.div<any>`
  width: 100%;
  height: auto;
  background-color: #e9e9eb;

  @keyframes pulse {
    from {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    to {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }

  .career-section {
    img {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      border-radius: 50%;
    }
    .career-link:hover {
      img {
        transition: ease 1s;
        animation: pulse infinite 1s;
        padding: 3px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .career-section {
      padding: 40px 20px;

      .job-position-wrapper {
        padding: 0px;

        h3 {
          font-size: 16px;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
        }

        .flex-col-80 {
          width: 80%;
          flex: 0 0 80%;
        }
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .career-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .career-section {
      padding: 120px 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    .career-section {
      padding: 120px 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .career-section {
      padding: 120px 150px;
    }
  }

  @media screen and (min-width: 1601px) {
    .career-section {
      padding: 120px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .career-section {
      padding: 120px 400px;
    }
  }
`;
