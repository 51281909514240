import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  AboveTitle,
  BigTitle,
  FlexCol,
  FlexWrapper,
  Title,
  Wrapper,
  Text,
  Bold,
  Img,
  ImgWrapper,
  SmallTitle,
  Divider,
} from '../../../../global/new-global-components';
import { ScenarioSectionWrapper } from './scenario-section.styles';

export const ScenarioSection = (props: any) => {
  const { t } = useTranslation();
  const { backgroundImage } = props;

  return (
    <ScenarioSectionWrapper>
      <Wrapper className="scenario-section">
        <AboveTitle>{t('about_new_Section2SubHeading')}</AboveTitle>
        <Title marginBottom="60px" fontWeight="800">
          {t('about_new_Section2Heading')}
        </Title>
        <Wrapper className="scenario-wrapper" padding="30px 65px" borderRadius="20px" backgroundColor="white" marginBottom="30px">
          <SmallTitle textAlign="left" fontSize="24px">
            {t('about_new_Section2Card1Title')}
          </SmallTitle>
          <Divider borderHeight=".5px" margin="15px 0" color="#B3BAAD" />
          <Text fontSize="16px">
            <Trans i18nKey="about_new_Section2Card1Text">
              part1 <Bold>part2</Bold>
            </Trans>
          </Text>
        </Wrapper>
        <Wrapper className="scenario-wrapper" padding="30px 65px" borderRadius="20px" backgroundColor="white">
          <SmallTitle textAlign="left" fontSize="24px">
            {t('about_new_Section2Card2Title')}
          </SmallTitle>
          <Divider borderHeight=".5px" margin="15px 0" color="#B3BAAD" />
          <Text fontSize="16px">
            <Trans i18nKey="about_new_Section2Card2Text">
              part1 <Bold>part2 </Bold>part3 <Bold>part4 </Bold> part5
            </Trans>
          </Text>
        </Wrapper>
      </Wrapper>
    </ScenarioSectionWrapper>
  );
};
