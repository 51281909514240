import styled from '@emotion/styled';
import css from '@emotion/css';

export const ScenarioSectionWrapper = styled.div<any>`
  width: 100%;
  height: auto;
  background-color: #e9e9eb;

  .scenario-section {
  }

  @media screen and (max-width: 768px) {
    .scenario-section {
      padding: 40px 20px;

      .scenario-wrapper {
        padding: 20px;
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .scenario-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .scenario-section {
      padding: 120px 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    .scenario-section {
      padding: 120px 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .scenario-section {
      padding: 120px 150px;
    }
  }

  @media screen and (min-width: 1601px) {
    .scenario-section {
      padding: 120px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .scenario-section {
      padding: 120px 400px;
    }
  }
`;
