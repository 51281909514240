import styled from '@emotion/styled';
import css from '@emotion/css';

export const WelcomeSectionWrapper = styled.div<any>`
  width: 100%;
  height: auto;
  background-color: white;

  @media screen and (max-width: 768px) {
    .welcome-section {
      padding: 40px 20px;

      .flex-col {
        width: 100%;

        div {
          padding: 0;
        }

        .welcome-video-image-wrapper {
          margin-top: 30px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .welcome-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .welcome-section {
      padding: 120px 50px;
    }

    .flex-col {
      p {
        font-size: 17px;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .welcome-section {
      padding: 120px 100px;
    }

    .flex-col {
      p {
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .welcome-section {
      padding: 120px 150px;
    }

    .flex-col {
      p {
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1601px) {
    .welcome-section {
      padding: 120px 200px;
    }
    .flex-col {
      p {
        font-size: 19px;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    .welcome-section {
      padding: 120px 400px;
    }

    .flex-col {
      p {
        font-size: 20px;
      }
    }
  }
`;
