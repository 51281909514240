import { VideoModal } from '../../../../components/layout/VideoModal/VideoModal';
import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import {
  AboveTitle,
  BigTitle,
  FlexCol,
  FlexWrapper,
  Title,
  Wrapper,
  Text,
  Bold,
  Img,
  ImgWrapper,
} from '../../../../global/new-global-components';
import { WelcomeSectionWrapper } from './welcome-section.styles';
import { useState } from 'react';

const posterImg = css`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 90px;
    height: 90px;
    background: url('/img/play.svg') center no-repeat white;
    background-size: 18px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    text-align: center;
  }

  &:hover {
    &:before {
      box-shadow: 0 0 5px 0 white inset, 0 0 5px 2px white;
      border-radius: 50%;
    }
  }
`;

export const WelcomeSection = (props: any) => {
  const { t } = useTranslation();
  const { backgroundImage, textSection, videoImage, aboveTitle, title, video, subtitle } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');
  const [subtitleSrc, setSubtitleSrc] = useState('');

  const hideModal = (): void => {
    setIsOpen(false);
  };

  const openModal = (videoSrc: string, subtitleSrc: string): void => {
    setVideoSrc(videoSrc);
    setSubtitleSrc(subtitleSrc);
    setIsOpen(true);
  };

  const onClick = () => {
    openModal(video, subtitle);
  };

  return (
    <WelcomeSectionWrapper backgroundImage={backgroundImage}>
      <VideoModal show={isOpen} onHide={hideModal} subtitleSrc={subtitleSrc} videoSrc={videoSrc} />
      <Wrapper className="welcome-section">
        <AboveTitle>{aboveTitle}</AboveTitle>
        <Title marginBottom="60px" fontWeight="800">
          {title}
        </Title>
        <FlexWrapper>
          <FlexCol className="flex-col" width="50%">
            <Wrapper paddingRight="100px">{textSection}</Wrapper>
          </FlexCol>
          <FlexCol className="flex-col" width="50%">
            <ImgWrapper className="welcome-video-image-wrapper" borderRadius="10px" overflow="hidden" onClick={onClick} css={posterImg}>
              <Img width="100%" src={videoImage} />
            </ImgWrapper>
          </FlexCol>
        </FlexWrapper>
      </Wrapper>
    </WelcomeSectionWrapper>
  );
};
