import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PageSection } from '../../components/layout/_/PageSection/PageSection.component';
import { Purpose } from './section/purpose/Purpose.section';
import { Vision } from './section/vision/Vision.section';
import { Mission } from './section/mission/Mission.section';
import { Strategy } from './section/strategy/Strategy.section';
import Values from './section/values/Values.section';

import { about } from './about.css';
import { HomePageLayout } from '../../layouts/HomePage.layout';
import { Container } from '../../layouts/main.layout';
import { HeroImageWithTextSection } from './section/hero_image_with_text_section/hero-image-with-text.section';
import { CoverageSection } from '../../pages_/home/new_coverage_section/coverage';
import { GetAppSection } from '../../pages_/home/new_get_app_section/getapp.section';
import { WelcomeSection } from './section/welcome_section/welcome.section';
import { ScenarioSection } from './section/scenario_section/scenario.section';
import { Bold, Text } from '../../global/new-global-components';
import { ValuesSection } from './section/new-values-section/value.section';
import { COUNTRY } from '../../configs/env';
import { CareerSection } from './section/new_career_section/career.section';
import { LanguageGuard } from '../../components/layout/LanguageGuard/LanguageGuard';

const AboutPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const LANG = i18n.language;

  const metaOgImg = `/img/meta-img/about-us-meta-og-2-1-new.jpg`;

  const meta = {
    title: t('meta_AboutTitle'),
    description: t('meta_AboutDescription'),
  };

  const og = {
    image: metaOgImg,
  };

  return (
    <HomePageLayout fullWidth={true} meta={meta} og={og}>
      <HeroImageWithTextSection
        backgroundMobileImage="/new-images/about-us-hero-mobile.png"
        backgroundImage="/new-images/about-us-hero.png"
      />
      <WelcomeSection
        aboveTitle={t('about_new_SubHeading')}
        title={t('about_new_Heading')}
        video={t('about_new_Section1VideoUrl')}
        subtitle={`/subtitles/${COUNTRY}/vozzi-30-na-20s.vtt`}
        textSection={
          <>
            <Text marginBottom="20px" fontWeight="800">
              {t('about_new_HeadingText')}
            </Text>
            <Text marginBottom="20px">
              <Trans i18nKey="about_new_Section1Paragraph1">
                part1 <Bold>part2</Bold>
              </Trans>
            </Text>
            <Text>
              <Trans i18nKey="about_new_Section1Paragraph2">
                part1 <Bold>part2</Bold>
              </Trans>
            </Text>
          </>
        }
        videoImage="/new-images/welcome-video-image.png"
      />

      <ScenarioSection />

      <WelcomeSection
        aboveTitle={t('about_new_Section3SubHeading')}
        title={t('about_new_Section3Heading')}
        video={t('about_new_Section3VideoUrl')}
        textSection={
          <>
            <Text marginBottom="20px">{t('about_new_Section3Paragraph1')}</Text>
            <Text marginBottom="20px">
              <Trans i18nKey="about_new_Section3Paragraph2">
                part1 <Bold>part2 </Bold>part3
              </Trans>
            </Text>
          </>
        }
        videoImage="/new-images/user-partner-video-image.png"
      />

      <GetAppSection
        side="right"
        mobileBackgroundImage="/new-images/about-us-getapp-mobile.png"
        backgroundImage="/new-images/about-us-getapp.png"
        elementPosition={4}
        customAboveTitle={t('about_new_InstallAppSectionSubHeading')}
        customTitle="about_new_InstallAppSectionHeading"
      />
      <CoverageSection customTitle="about_new_TimelineHeading" customSubTitle="about_new_TimelineSubText" />
      <ValuesSection />
      <LanguageGuard visible={[{ country: 'rs' }]}>
        <CareerSection />
      </LanguageGuard>
    </HomePageLayout>
  );
};

export default AboutPage;
