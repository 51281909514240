export const positions = [
  // {
  //   url: 'backend-developer',
  //   title: 'Backend Developer - Senior',
  //   subTitle: 'We are hiring a full-time backend software engineer',
  // },
  {
    url: 'frontend-developer',
    title: 'Frontend Developer - Senior',
    subTitle: 'We are hiring a full-time frontend software engineer',
  },
  // {
  //   url: 'nodejs-developer',
  //   title: 'Node.js Developer - Senior',
  //   subTitle: 'We are hiring a full-time frontend software engineer',
  // },
  // {
  //   url: 'digital-marketing-manager',
  //   title: 'Digital Marketing Specialist',
  //   subTitle: 'We are hiring a person skilled in digital marketing',
  // },
  // {
  //   url: 'product-developer-ux-designer',
  //   title: 'Product Developer / UX designer',
  //   subTitle: 'We are hiring a person skilled in creating delightful user experiences',
  // },
  // {
  //   url: 'marketing-assistant',
  //   title: 'Marketing Assistant',
  //   subTitle: 'We are hiring a Marketing Assitant',
  // },
  // {
  //   url: 'contentwriter',
  //   title: 'Copywriter / Content Writer',
  //   subTitle: 'We are hiring a person skilled in copywriting and content writing',
  // },
  // {
  //   url: 'op-center-pl',
  //   title: 'Radnik u operativnom centru (poljski jezik)',
  //   subTitle: 'We are hiring a person skilled in customer support in Polish language',
  // },
  // {
  //   url: 'op-center-cs',
  //   title: 'Radnik u operativnom centru (češki jezik)',
  //   subTitle: 'We are hiring a person skilled in customer support in Czech language',
  // },
  // {
  //   url: 'op-center-sk',
  //   title: 'Radnik u operativnom centru (slovački jezik)',
  //   subTitle: 'We are hiring a person skilled in customer support in Slovak language',
  // },
  // {
  //   url: 'op-center-sl',
  //   title: 'Radnik u operativnom centru (slovenački jezik)',
  //   subTitle: 'We are hiring a person skilled in customer support in Slovenian language',
  // },
  // {
  //   url: 'op-center-sq',
  //   title: 'Radnik u operativnom centru (albanski jezik)',
  //   subTitle: 'We are hiring a person skilled in customer support in Albanian language',
  // },
  // {
  //   url: 'sales-agent',
  //   title: 'Agent prodaje',
  //   subTitle: 'We are hiring a person skilled in sales',
  // },
  // {
  //   url: 'op-center-gr',
  //   title: 'Radnik u operativnom centru (grčki jezik)',
  //   subTitle: 'We are hiring a person skilled in customer support in Greek language',
  // },
  // {
  //   url: 'op-center-bg',
  //   title: 'Radnik u operativnom centru (bugarski jezik)',
  //   subTitle: 'We are hiring a person skilled in customer support in Bulgarian language',
  // },
  // {
  //   url: 'op-center-ro',
  //   title: 'Radnik u operativnom centru (rumunski jezik)',
  //   subTitle: 'We are hiring a person skilled in customer support in Romanian language',
  // },
  // {
  //   url: 'op-center-general',
  //   title: 'Radnik u operativnom centru',
  //   subTitle: 'We are hiring a person skilled in customer support',
  // },
];
