import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AboveTitle,
  BigTitle,
  FlexCol,
  FlexWrapper,
  Title,
  Wrapper,
  Text,
  Bold,
  Img,
  ImgWrapper,
  SmallTitle,
  Divider,
} from '../../../../global/new-global-components';
import { ValuesSectionWrapper } from './values-section.styles';

export const ValuesSection = (props: any) => {
  const { t } = useTranslation();

  return (
    <ValuesSectionWrapper>
      <Wrapper className="values-section">
        <AboveTitle marginBottom="15px" textAlign="left">
          vrednosti koje cenimo
        </AboveTitle>
        <Title marginBottom="15px" textAlign="left" fontWeight="800">
          {t('about_new_CompanyCultureHeading')}
        </Title>
        <Text fontSize="20px">{t('about_new_CompanyCultureSubHeading')}</Text>

        <Wrapper borderRadius="20px" marginTop="30px" overflow="hidden">
          <FlexWrapper flexWrap="wrap" backgroundColor="#F4F4F4">
            <FlexCol className="flex-col-text" width="25%" padding="30px">
              <SmallTitle fontSize="24px" fontWeight="800">
                {t('about_ValuesSubHeadingOne')}
              </SmallTitle>
              <Text fontSize="16px">{t('about_ValuesTextOne')}</Text>
            </FlexCol>
            <FlexCol className="flex-col-text" width="25%" padding="30px">
              <SmallTitle fontSize="24px" fontWeight="800">
                {t('about_ValuesSubHeadingTwo')}
              </SmallTitle>
              <Text fontSize="16px">{t('about_ValuesTextTwo')}</Text>
            </FlexCol>
            <FlexCol className="flex-col-text" width="25%" padding="30px">
              <SmallTitle fontSize="24px" fontWeight="800">
                {t('about_ValuesSubHeadingThree')}
              </SmallTitle>
              <Text fontSize="16px">{t('about_ValuesTextThree')}</Text>
            </FlexCol>
            <FlexCol className="flex-col-text" width="25%" padding="30px">
              <SmallTitle fontSize="24px" fontWeight="800">
                {t('about_ValuesSubHeadingFour')}
              </SmallTitle>
              <Text fontSize="16px">{t('about_ValuesTextFour')}</Text>
            </FlexCol>
            <FlexCol className="flex-col-text" width="25%" padding="30px">
              <SmallTitle fontSize="24px" fontWeight="800">
                {t('about_ValuesSubHeadingFive')}
              </SmallTitle>
              <Text fontSize="16px">{t('about_ValuesTextFive')}</Text>
            </FlexCol>
            <FlexCol className="flex-col-text" width="25%" padding="30px">
              <SmallTitle fontSize="24px" fontWeight="800">
                {t('about_ValuesSubHeadingSix')}
              </SmallTitle>
              <Text fontSize="16px">{t('about_ValuesTextSix')}</Text>
            </FlexCol>
            <FlexCol className="flex-col-text" width="25%" padding="30px">
              <SmallTitle fontSize="24px" fontWeight="800">
                {t('about_ValuesSubHeadingSeven')}
              </SmallTitle>
              <Text fontSize="16px">{t('about_ValuesTextSeven')}</Text>
            </FlexCol>
            <FlexCol className="flex-col-text" width="25%" padding="30px">
              <SmallTitle fontSize="24px" fontWeight="800">
                {t('about_ValuesSubHeadingEight')}
              </SmallTitle>
              <Text fontSize="16px">{t('about_ValuesTextEight')}</Text>
            </FlexCol>
          </FlexWrapper>
        </Wrapper>
      </Wrapper>
    </ValuesSectionWrapper>
  );
};
