import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { AboveTitle, BigTitle, FlexWrapper, Title, Wrapper } from '../../../../global/new-global-components';
import { HeroImageWithTextWrapper } from './hero-image-with-text.styles';

export const HeroImageWithTextSection = (props: any) => {
  const { t } = useTranslation();
  const { backgroundImage, backgroundMobileImage } = props;

  return (
    <HeroImageWithTextWrapper backgroundImage={backgroundImage} backgroundMobileImage={backgroundMobileImage}>
      <FlexWrapper justifyContent="center" backgroundColor="rgba(0,0,0, .4)" position="absolute" height="100%" width="100%">
        <BigTitle fontWeight="800" color="white">
          <Trans i18nKey={'about_new_HeroHeadline'}>
            part1<span className="break-on-md-and-lg"> part2</span>
          </Trans>
        </BigTitle>
      </FlexWrapper>
    </HeroImageWithTextWrapper>
  );
};
